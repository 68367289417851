import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"

const classNames = require('classname')
const styles = require('../styles/terms.module.css')

const NoticesPage: React.FC = () => (
    <Layout>
        <SEO title="Notices" />
        <div className={styles.container}>
            <div className={classNames({ [styles.main]: true, [styles.mainRaised]: true })}>
                <div className={styles.blueBanner}>
                    <div className={styles.space150}></div>
                    <div className={styles.header}>
                        <h1 className={styles.statement}>Notices</h1>
                    </div>
                    <div className={styles.space150}></div>
                </div>
                <div className={styles.mainPadding}>
                <p>
                    © Park to Give and contributors 2019
                </p>
                <p>
                    Except as permitted by the copyright law applicable to you, you may not reproduce or communicate any of the content on this website,
                    including files downloadable from this website, without the permission of the copyright owner.
                </p>
                <p>
                    Contributors
                </p>
                <p>
                    “From the top of Finlay Park view of Columbia SC” (c) by Klinhphotog, retrieved from <br />
                    https://commons.wikimedia.org/wiki/File:Columbia_SC_Finlay_Park.jpg, used under Creative<br />
                    Commons Attribution-ShareAlike 3.0 Unported (http://creativecommons.org/licenses/bysa/3.0/)
                </p>
                <p>
                    We may change these terms of use from time to time. Check before re-using any content from this website.
                </p>
                </div>
            </div>
        </div>
    </Layout>
)

export default NoticesPage